.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 540px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-white;
        padding: 30px 0;
    }

    &__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        color: $color-dark;
        letter-spacing: 0.05em;
        margin: 0 0 10px;
        width: fit-content;
        position: relative;
        text-transform: uppercase;

        &:before {
            position: absolute;
            content: " ";
            width: 110px;
            height: 25px;
            left: -15px;
            bottom: -10px;
            mask-image: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/home/Ligne-titre-css.svg);
            mask-repeat: no-repeat;
            background-color: $color-second;
        }
    }

    &__lead-wrapper {
        p {
            color: $color-text;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
        }
    }

    &__intro {
        color: $color-text;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        margin: 30px 0 0;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 435px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 350px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 250px;
        }

        &__title {
            font-size: 28px;
            line-height: 40px;
        }
    }

}
