.header {
    position: relative;

    &--fixed {
        .header-top {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 0;
            z-index: 20;
            height: 80px;
            box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
            transition: all 0.25s ease-in-out;
        }
    }
}

.header-top {
    height: 80px;
    background-color: $color-white;
    
    &__container {
        display: flex;
        gap: 65px;
    }

    &__logo {
        position: relative;
        z-index: 11;
        width: 235px;
        height: 130px;
        background: $color-main;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 10px 10px;
    }

    &__title {
        font-size: 0;
        line-height: 0;
        margin: 0;
    }

    &__content {
        display: flex;
    }

    &__participe {
        width: 160px;
        height: 95px;
        background: $color-second;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5px;
        border-radius: 0px 0px 5px 5px;
        z-index: 11;
        transition: all 0.2s ease-in-out;

        svg {
            width: 30px; 
            height: 30px;
        }

        p {
            font-weight: $font-weight-semibold;
            font-size: 18px;
            line-height: 20px;

            span {
                font-weight: $font-weight-light;
            }
        }

        &:hover {
            color: $color-white;
            gap: 0;
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    display: flex;
    gap: 15px;
    margin-left: 45px;

    .tool {
        margin: 0;
        padding: 0;
        width: 40px;
        height: 60px;
        border-radius: 0 0 5px 5px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;
        border: none;
        transition: all $duration ease-in-out;

        svg {
            width: 30px;
            height: 30px;
            transition: all $duration ease-in-out;
        }
    }

    &__accessibility {
        background-color: $color-dark;

        &:hover {
            background-color: $color-light;

            svg {
                fill: $color-dark;
            }
        }
    }

    &__search {
        background-color: $color-main;

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        &:hover {
            background-color: $color-second;
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border: 2px solid $color-white;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header-top {
        &__container {
            gap: 38px;
        }

        &__logo {
            width: 200px;
            height: 110px;
        }

        &__title {
            svg {
                width: 157px;
                height: 76px;
            }
        }

        &__participe {
            width: 130px;
            height: 95px;

            p {
                font-size: 16px;
            }
        }
    }

    .tools {
        margin-left: 30px;
    }
}


// 960
@media screen and (max-width: $medium) {

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .header-top {
        &__container {
            justify-content: space-between;
        }

        &__logo {
            width: 235px;
            height: 130px;

            svg {
                width: 185px;
                height: 90px;
            }
        }

        &__participe {
            display: none;
        }

        &__content {
            gap: 50px;
        }
    }

    .tools {
        margin-left: 0;
    }
  
}


// 640
@media screen and (max-width: $small) {
    .header-top {
        height: 90px;

        &__container {
            gap: 20px;
        }

        &__logo {
            width: 195px;
            min-width: 195px;
            height: 110px;

            svg {
                width: 155px;
                height: 75px;
                margin-top: 5px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 8px;
        }
    }

    .tools {
        gap: 5px;

        .tool {
            height: 40px;
        }
    }
}
