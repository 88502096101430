.admin-bar .acf-ui-datepicker .ui-datepicker.ui-widget-content {
    transform: translate(0px, -33px);
}

.acf-ui-datepicker {

    .ui-datepicker.ui-widget-content {
        width: 370px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
        transform: translateY(-2px);

        .ui-icon {
            filter: contrast(0.5);
        }

        .ui-datepicker-title {
            display: flex;
            justify-content: center;

            .ui-datepicker-month {
                margin-right: 10px;
            }

            .ui-datepicker-month,
            .ui-datepicker-year {
                width: 80px;
            }
        }

        .ui-widget-header {
            .ui-state-hover {
                border: 1px solid $color-main;
                background: $color-main;
                color: $color-white;
            }
        }

        td {
            a {
                padding: .5em;

                &.ui-state-default {
                    border: 1px solid #dcdcdc;
                    background: $color-bg--neutral;
                    color: $color-text;
                }

                &.ui-state-highlight {
                    background: $color-second;
                    color: $color-white;
                }

                &.ui-state-hover,
                &.ui-state-active {
                    border: 1px solid $color-main;
                    background: $color-main;
                    color: $color-white;

                    &.ui-state-highlight {
                        border: 1px solid $color-main !important;
                        background: $color-main !important;
                    }
                }

            }
        }

        button {
            border: $btn-border-width $btn-border-style $color-second;
            color: $color-second;
            background: $btn-second-bg;
            padding: 10px 25px;
            margin: 10px 0;
            font-weight: $btn-font-weight;
            opacity: 1;

            &:hover,
            &:focus,
            &:active,
            &.is-active,
            &.is-checked,
            &.ui-state-hover{
                color: $btn-second-color--hover;
                background: $color-second;
                border: $btn-border-width $btn-border-style $color-second;
            }
        }
    }

    .ui-timepicker-div {
        dl {
            dd.ui_tpicker_second {
                display: none;
            }
        }
    }

}


.admin-bar .ui-datepicker.ui-widget-content {
    transform: translate(0px, -33px);
}
.ui-datepicker.ui-widget-content {
    width: 370px;
    background-color: $color-white;
    border: 10px solid $color-bg--neutral;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
    transform: translateY(-2px);
    margin-top: 0;

    .ui-datepicker-header {
        border: 1px solid $color-white;
        background: $color-white;
        color: $color-text;
        font-weight: $font-weight-bold;
    }

    table {
        margin: 0 0 .4em !important;

        thead {
            background: $color-white;
            border: 0;
    
            th {
                color: #444;
                font-size: .87rem;
                text-shadow: none;
            }
        }

        tbody {
            tr {
                border: 0;
            }
            td {
                border: 0;
                padding: 1px;
            }
        }
    }

    .ui-datepicker-prev, .ui-datepicker-next {
        top: 7px;
        filter: contrast(0.5);
        border: 1px solid $color-bg--neutral;
    }

    .ui-datepicker-prev {
        background-position: center -33px;
    }

    .ui-datepicker-next {
        background-position: center -3px;
    }

    .ui-datepicker-title {
        display: flex;
        justify-content: center;

        .ui-datepicker-month {
            margin-right: 10px;
        }

        .ui-datepicker-month,
        .ui-datepicker-year {
            width: 80px;
        }
    }

    .ui-widget-header {
        .ui-state-hover {
            top: 7px;
            border: 1px solid $color-dark;
            color: $color-white;
        }
    }

    td.ui-datepicker-unselectable.ui-state-disabled {
        background-color: $color-white;
    }

    td {
        a {
            padding: .5em;

            &.ui-state-default {
                border: 1px solid #dcdcdc;
                background: $color-bg--neutral;
                color: $color-text;
                margin: 0;
                width: 100%;
                box-shadow: none;      
                text-shadow: none;      
            }

            &.ui-state-highlight {
                background: $color-white;
            }

            &.ui-state-hover,
            &.ui-state-active {
                border: 1px solid $color-dark;
                background: $color-dark;
                color: $color-white;

                &.ui-state-highlight {
                    border: 1px solid $color-dark !important;
                    background: $color-dark !important;
                }
            }

        }

        a, span {
            height: 37px;
            line-height: 27px;
        }
    }
}
.ui-datepicker-calendar tr:last-child .ui-state-active {
    height: 37px !important;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .acf-ui-datepicker {
        .ui-datepicker.ui-widget-content {
            width: 300px;
        }
    }
    
    .ui-datepicker.ui-widget-content {
        width: 300px;
    }
}


// 640
@media screen and (max-width: $small) {

    .admin-bar .acf-ui-datepicker .ui-datepicker.ui-widget-content,
    .admin-bar .ui-datepicker.ui-widget-content {
        transform: translateY(-46px);
    }
}
