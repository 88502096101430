.footer {
    background-color: $color-dark;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/home/motif.png);
        background-repeat: repeat;
        z-index: 0;
    }

    &__container {
        position: relative;
        z-index: 2;
    }

    &__infos {
        display: flex;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 270px;
        margin-top: 60px;
        gap: 50px;
    }

    &__button {
        width: 270px!important;
    }

    &__title {
        color: $color-white;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
    }

    &__text {
        color: $color-white;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        margin: 14px 0 41px;
    }

    &__coordonnees {
        width: 270px;
        margin-left: 110px;
        margin-top: 80px;
    }

    &__phone {
        width: 270px!important;
        background-color: $color-white!important;
        color: $color-dark!important;

        svg {
            fill: $color-dark!important;
        }
    }

    &__horaires {
        width: 380px;
        margin-left: 110px;
        margin-top: 80px;
    }

    &__labels {
        width: 270px!important;
        background-color: $color-second!important;
    }

    &__bottom {
        display: flex;

        a:not(:first-child) {
            margin-left: 110px;
        }
    }

    &__menu {
        padding: 40px 0;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            gap: 60px;
        }

        li {
            a {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.05em;
                color: $color-white;
                &:hover {
                    color: $color-light;
                }
            } 
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        &__logo {
            min-width: 270px;
            gap: 74px;
        }

        &__coordonnees {
            margin-left: 65px;

            .footer__text {
                margin: 14px 0 65px;
            }
        }

        &__horaires {
            margin-left: 65px;
            width: 270px;

            .footer__text {
                margin: 14px 0 40px;
            }
        }

        &__bottom {
            a:not(:first-child) {
                margin-left: 65px;
            }
        }

        &__menu {
            padding: 40px 0 50px;
            
            ul {
                gap: 45px;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        &__infos {
            flex-direction: column;
            align-items: center;
        }

        &__coordonnees {
            margin: 0;
            margin-top: 0px;
            width: unset;
            margin-top: 30px;

            .footer__text {
                text-align: center;
                margin: 14px 0 0;
            }
        }

        &__horaires {
            margin: 0;
            margin-top: 30px;

            .footer__text {
                text-align: center;
                margin: 14px 0 0;
            }
        }

        &__title {
            text-align: center;
        }

        &__bottom {
            margin-top: 32px;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            a:not(:first-child) {
                margin-left: 0;
            }
        }

        &__menu {
            padding: 47px 0 50px;

            ul {
                gap: 16px 50px;
                justify-content: center;
            }
        }
    }

}