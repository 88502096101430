.front-page {
    &__title  {
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 0.05em;
        margin: 0;
        padding: 0;
        width: fit-content;
        text-transform: uppercase;
        position: relative;
        color: $color-dark;

        span {
            font-weight: 300;
        }

        &:before {
            position: absolute;
            content: " ";
            width: 110px;
            height: 25px;
            right: -20px;
            bottom: -10px;
            mask-image: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/home/Ligne-titre.svg);
            mask-repeat: no-repeat;
            background-color: $color-second;
        }
    }

    &__button {
        color: $color-white;
        background-color: $color-main;
        width: 300px;
        min-height: 40px;
        padding: 0;
        border-radius: $border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        transition: all $duration ease-in-out;

        svg {
            width: 20px;
            height: 20px;
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            color: $color-dark;
            gap: 7px;
            background-color: $color-light!important;

            svg {
                fill: $color-dark;
            }
        }
    }

    &__zone {
        display: flex;
        gap: 75px;
        margin: 60px auto 80px;
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none
        }
    }

    .slideshow {
        height: 560px;
        width: 100%;
        overflow: hidden;
        padding-bottom: 20px;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            right: 140px;
            bottom: -20px;
            background: $color-dark;
            color: $color-white;
            max-width: 660px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 7.5px 20px;
        }

        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Navigation (arrow)
        &__navigation {
            position: absolute;
            right: 105px;
            left: unset;
            top: unset;
            height: fit-content;
            pointer-events: none;
            bottom: 0;
            height: 40px;
    
            &__btn {
                pointer-events: auto;
                top: unset;
                left: unset;
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 0;
                background: $color-main;
                border: 0;
                border-radius: 5px;
                transition: all $duration ease-in-out;

                &--prev, &--next {

                    &::after {
                        position: relative;
                        content: "";
                        top: 0;
                        width: 30px;
                        height: 30px;
                        mask-repeat: no-repeat;
                        mask-size: 30px;
                        background-color: $color-white;
                        transition: all $duration ease;
                    }
                }

                &--prev {
                    right: -35px;
                    &::after {
                        mask-image: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/Pictos/ic-nav-arrow-gauche.svg);
                    }
                }

                &--next {
                    right: -90px;
                    &::after {
                        mask-image: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/Pictos/ic-nav-arrow-droite.svg);
    
                    }
                }

                &:hover {
                    background-color: $color-second;
                }
            }
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    margin-top: 60px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 415px;
        left: 0;
        top: 175px;
        background: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/home/motif.png);
        background-repeat: repeat;
        z-index: -1;
    }

    &__list {
        margin-top: 30px;
        display: flex;
        gap: 30px;
    }

    &__item {
        width: 270px;
    }

    &__link {
        position: relative;

        &:hover, &:focus {
            .news__taxonomy {
                background-color: $color-light;
                color: $color-dark;
                
                span {
                    background-color: $color-dark;
                }
            }
        }
    }

    &__image {
        position: relative;
        width: 270px;
        height: 200px;
        
        img {
            transform: scale(1);
            transition: all $duration ease;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-gray;
        background-position: center;
        width: 270px;
        height: 200px;
    }

    &__taxonomy {
        color: $color-white;
        background: $color-second;
        display: flex;
        gap: 10px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        max-width: 240px;
        // margin-top: -15px;
        margin-left: 15px;
        width: fit-content;
        height: fit-content;
        padding: 5px 20px;
        align-items: flex-start;
        text-transform: uppercase;
        z-index: 5;
        position: absolute;
        bottom: -15px;
        transition: all $duration ease-in-out;

        span {
            background-color: $color-white;
            width: 6px;
            height: 6px;
            border-radius: 50px;
            min-width: 6px !important;
            margin-top: 7px;
            transition: all $duration ease-in-out;
        }
    }

    &__title--small {
        color: $color-dark;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        margin: 25px 0 0;
    }

    &__buttons {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
    }
}

//======================================================================================================
// Accès rapides 
//======================================================================================================

.access {
    margin-top: 60px;

    &__list {
        background-color: $color-dark;
        border-radius: 10px;
        padding: 58px 50px;
        column-count: 3;
        gap: 100px;
        position: relative;
        z-index: 2;

        li:nth-child(even) {
            margin-top: 15px;
        }

        li:nth-child(3), li:nth-child(4) {
            margin-left: 20px;
        }
    }

    &__link {
        color: $color-white;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        span {
            font-weight: 600;
            margin-left: 7px;
        }

        div {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            transition: all $duration ease-in-out;
            position: relative;

            &:before {
                position: absolute;
                content: " ";
                width: 0;
                height: 0;
                background-color: $color-white;
                border-radius: 5px;
                transition: all $duration ease-in-out;
                z-index: -1;
            }

            svg {
                width: 30px;
                height: 30px;
                
            }
        }

        &:hover, &:focus {
            color: $color-white;

            div:before {
                width: 100%;
                height: 100%;
            }
        }
    }
}

//======================================================================================================
// Événements
//======================================================================================================

.home-events {
    margin-top: 80px;
    position: relative;

    &:before, &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 365px;
        bottom: 20px;
        left: 0;
        z-index: -1;
    }

    &:before {
        background-color: $color-light;
    }

    &:after {
        background: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/home/motif.png);
        background-repeat: repeat;
    }

    &__container {
        overflow: hidden;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        &:before {
            background-color: $color-main;
        }
    }

    &__navigation {
        width: 95px;
        display: flex;
        align-items: center;

        &__prev,
        &__next {
            top: unset;
            right: unset;
            left: unset;
            bottom: unset;
            margin-top: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin: 0;
            padding: 0;
            background-color: $color-second;
            border: 0;
            border-radius: 5px;
            transition: all $duration ease;

            &::after {
                position: relative;
                content: "";
                top: 0;
                width: 30px;
                height: 30px;
                mask-repeat: no-repeat;
                mask-size: 30px;
                background-color: $color-white;
                transition: all $duration ease;
            }

            &:hover {
                background-color: $color-main;
            }
        }

        &__prev {
            
            &::after {
                mask-image: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/Pictos/ic-nav-arrow-gauche.svg);
            }
        }

        &__next {
            margin-left: 55px;
            &::after {
                mask-image: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/Pictos/ic-nav-arrow-droite.svg);
            }
        }
    }

    &__swiper {
        margin-top: 40px;
        overflow: visible;
    }

    &__link {
        display: flex;
        flex-direction: row-reverse;
        border-radius: 10px;
        overflow: hidden;
        width: 570px;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

        &:hover, &:focus {
            .home-events__date {
                background-color: $color-second;
            }

            .home-events__location {
                color: $color-dark;
            }
        }
    }

    &__image {
        position: relative;
        width: 270px;
        height: 205px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-gray;
        background-position: center;
        position: relative;
        width: 270px;
        height: 205px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 30px;
        width: 240px;
        background-color: $color-white;
    }

    &__title--small {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: $color-dark;
        margin-bottom: 15px;
    }

    &__location {
        font-style: italic;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        align-items: flex-start;
        gap: 5px;
        margin-bottom: 15px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__date {
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 5px;
        color: $color-white;
        background-color: $color-main;
        border-radius: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        height: 40px;
        padding: 0 20px;
        transition: all $duration ease-in-out;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-top: 55px;

        .home-events__button:first-child {
            background-color: $color-second;
        }

        .home-events__button:last-child {
            background-color: $color-dark;
        }
    }

    &__button {
        &:hover, &:focus {
            color: $color-white;
            background-color: $color-main!important;

            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Agenda Culturel
//======================================================================================================

.agenda {
    margin-top: 30px;
    display: flex;

    &__title {
        padding: 0;
        color: $color-dark;
        font-weight: 300;
        font-size: 30px;
        line-height: 40px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        letter-spacing: 0.05em;
        margin: 45px 0 0;

        span {
            font-weight: 600;
        }
    }

    &__list {
        display: flex;
        margin-left: 135px;
        gap: 30px;
    }

    &__item {
        width: 270px;
        height: 150px;
        background-color: $color-dark;
        border-radius: 10px;
        padding: 30px 15px 40px 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;

        &:hover, &:focus {
            .agenda__date {
                background-color: $color-second;
            }
        }
    }

    &__category {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        color: $color-light;
        display: flex;
        align-items: center;
        gap: 10px;

        span {
            width: 6px;
            height: 6px;
            background-color: $color-light;
            border-radius: 50px;
        }
    }

    &__title--small {
        margin: 10px 0 0;
        padding: 0;
        color: $color-white;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
    }

    &__date {
        position: absolute;
        bottom: -15px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 5px;
        color: $color-white;
        background-color: $color-main;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        padding: 0 20px;
        transition: all $duration ease-in-out;
    }
}

//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {
    display: flex;
    gap: 30px;
    padding-top: 30px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 240px;
        left: -25px;
        bottom: 45px;
        background: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/home/motif.png);
        background-repeat: repeat;
        z-index: -1;
    }

    &__title {
        &:before {
            display: none;
        }
    }

    &__right {
        display: flex;
        gap: 30px;
    }
    
    &__navigation {
        margin-top: 50px;
        width: 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__btn {
            border-radius: 3px;
            position: initial;
            margin: 0;
            width: 40px;
            height: 40px;
            padding: 0;
            background-color: $color-main;

            &:after {
                content: none;
            }

            svg {
                height: 30px;
                width: 30px;
                fill: $color-white;
            }
        }
    }

    .swiper-container {
        &__image {
            overflow: hidden;
            width: 190px;
            height: 255px;
            box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,0.2);

            .swiper-slide {
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }
            }
        }

        &__content {
            width: 270px;
            margin: 0;

            .kiosque-content {
                &__container {
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                &__infos {
                    font-style: italic;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 10px 0 20px;
                }

                &__buttons {
                    display: flex;
                    gap: 20px;

                    &--button {
                        width: 38px;
                        height: 38px;
                        border: 1px solid $color-dark;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all $duration ease-in-out;

                        svg {
                            width: 30px;
                            height: 30px;
                            fill: $color-dark;
                            transition: all $duration ease-in-out;
                        }

                        &:hover, &:focus {
                            background-color: $color-dark;

                            svg path {
                                fill: $color-white;
                            }
                        }
                    }
                }
            }

            .swiper-slide {
                .kiosque-content {
                    opacity: 0;
                }
            }
            .swiper-slide-active {
                .kiosque-content {
                    opacity: 1;
                    position: absolute;
                    z-index: 5;
                }
            }
        }
    }

    &__buttons {
        margin-top: 50px;
    }

    &__button {
        padding: 0 25px;
        background-color: $color-second;
        width: 270px;
    }
}

//======================================================================================================
// Section réseaux sociaux
//======================================================================================================

.home-social {
    padding: 50px 90px 45px 50px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 1000px;
        height: 290px;
        background-color: $color-main;
        border-radius: 10px;
        z-index: -1;
    }

    &:after {
        position: absolute;
        content: "";
        width: 260px;
        height: 305px;
        right: -95px;
        bottom: -40px;
        background: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/home/Aigle.svg);
        background-repeat: no-repeat;
        z-index: -1;
    }

    p {
        font-style: italic;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: $color-white;
    }

    &__pictos {
        margin: 25px 0;
        display: flex;
        gap: 30px;
    }

    &__picto {
        width: 40px;
        height: 40px;

        img {
            transform: scale(1);
            transition: transform 0.25s ease-in-out;
            width: 40px;
            height: 40px;
            border-radius: 6px;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &__button {
        width: 390px;
        background-color: $color-dark;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        &__zone {
            margin: 40px auto 80px;
            gap: 50px;
        }
    }

    .home-slideshow {
        .slideshow {
            height: 455px;

            &__navigation {
                right: 100px;
            }

            &__content {
                right: 130px;
            }
        }
    }

    .news {
        &:before {
            height: 420px;
            top: 165px;
        }

        &__list {
            gap: 20px;
        }

        &__image {
            width: 220px;
            height: 165px;
        }

        .notimg {
            width: 220px;
            height: 165px;
        }

        &__taxonomy {
            max-width: 190px;
        }
    }

    .access {
        &__list {
            padding: 56px 38px;
            gap: 35px;

            li {
                max-height: 30px;

                &:nth-child(2n) {
                    margin-top: 22px;
                }
            }
        }

        &__link {
            font-size: 16px;
        }
    }

    .home-events {
        &__link {
            width: 460px;
        }

        &__image {
            width: 220px;
            height: 225px;
        }
    
        .notimg {
            width: 220px;
            height: 225px;
        }

        &__content {
            padding: 20px;
            width: 200px;
        }

        &__title--small {
            font-size: 18px;
        }

        &__date {
            font-size: 18px;
        }
    }

    .agenda {
        margin-top: 20px;

        &__list {
            margin-left: 73px;
            gap: 20px;
        }

        &__item {
            width: 220px;
        }

        &__title--small {
            font-size: 18px;
        }
    }

    .kiosque {
        gap: 20px;

        &:before {
            width: 520px;
            height: 240px;
            bottom: 50px;
        }

        .swiper-container {
            &__content {
                width: 240px;
            }
        }

        &__right {
            margin-top: 5px;
            gap: 20px;
        }

        &__navigation {
            margin-top: 45px;
        }

        &__button {
            padding: 0 9px;
            width: 240px;
        }
    }

    .home-social {
        padding: 50px 30px 45px;

        &:after {
            right: -140px;
        }

        &__pictos {
            gap: 20px;
        }

        &__button {
            width: 350px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .front-page {
        &__zone {
            margin: 65px auto 80px;
            gap: 115px;
        }
    }

    .home-slideshow {
        .slideshow {
            height: 370px;

            &__content {
                max-width: 510px;
            }
        }
    }

    .news {
        &:before {
            height: 760px;
            top: 190px;
        }

        &__list {
            flex-wrap: wrap;
        }

        &__item {
            width: 300px;
        }

        &__image {
            width: 300px;
            height: 225px;
        }

        .notimg {
            width: 300px;
            height: 225px;
        }

        &__taxonomy {
            max-width: 270px;
        }
    }

    .access {
        &__list {
            padding: 49px 0 49px 27px;
            gap: 14px 34px;
            column-count: unset;
            display: flex;
            flex-wrap: wrap;

            li {
                max-height: unset;
                margin: 0px!important;
                width: 266px;

                &:nth-child(1) {
                    order: 1;
                }

                &:nth-child(2) {
                    order: 3;
                }

                &:nth-child(3) {
                    order: 2;
                }

                &:nth-child(4) {
                    order: 4;
                }

                &:nth-child(5) {
                    order: 5;
                }

                &:nth-child(6) {
                    order: 6;
                }
            }
        }

        &__link div {
            margin-right: 13px;
        }
    }

    .home-events {
        margin-top: 60px;

        &:before, &:after {
            height: 715px;
            bottom: 35px;
        }

        &__container {
            position: relative;
            padding-bottom: 15px;
        }

        &__link {
            width: 300px;
            flex-direction: column-reverse;
        }

        &__image {
            width: 300px;
            height: 205px;
        }

        &__title--small {
            font-size: 18px;
        }
    
        .notimg {
            width: 300px;
            height: 205px;
        }

        &__content {
            width: 260px;
            height: 165px;
        }

        &__buttons {
            flex-direction: column;
            position: absolute;
            right: 10px;
            bottom: 15px;
        }
    }

    .agenda {
        flex-direction: column;

        &__title {
            margin: 0;
            flex-direction: row;
            gap: 10px;
        }

        &__list {
            margin-left: 0;
            gap: 35px 20px;
            flex-wrap: wrap;
            margin-top: 25px;
        }

        &__item {
            width: 300px;
        }
    }

    .kiosque {
        padding-top: 15px;

        &:before {
            width: 320px;
            height: 380px;
            left: -25px;
            bottom: 20px;
        }

        &__title {
            flex-direction: column;
            display: flex;
            font-size: 28px;
            line-height: 40px;
            margin-top: 4px;
        }

        &__left {
            position: relative;
        }

        &__right {
            display: flex;
            flex-direction: column;

            &--infos {
                display: flex;
            }
        }

        .swiper-container {
            &__content {
                width: 150px;
                height: 250px;
                margin: 0;
                margin-left: 35px;

                .kiosque-content {
                    &__infos {
                        position: absolute;
                        bottom: -50px;
                        width: 185px;
                        right: -101px;
                        margin: 0;
                        display: flex;
                        justify-content: flex-end;
                    }
        
                    &__buttons {
                        flex-direction: column;
                        gap: 15px;
                        margin-top: 25px;
                    }
                }
            }

            &__image {
                position: absolute;
                left: 95px;
            }
        }

        &__navigation {
            position: absolute;
            margin: 0;
            bottom: 65px;
            flex-direction: row;
            width: 90px;
            right: -95px;
        }

        &__button {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 300px;
        }
    }

    .home-social {
        &:before {
            height: 400px;
        }

        &:after {
            right: -120px;
            bottom: 52px;
        }

        p {
            width: 270px;
        }

        &__pictos {
            width: 160px;
            flex-wrap: wrap;
        }

        &__button {
            width: 270px;
            margin-top: 35px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .front-page {
        &__title {
            font-size: 28px;

            &:before {
                right: -15px;
                bottom: -5px;
            }
        }

        &__zone {
            flex-direction: column;
            margin: 60px auto 80px;
            gap: 60px;
        }
    }

    .home-slideshow {
        .slideshow {
            height: 310px;

            img {
                height: 250px;
            }

            &__content {
                bottom: 30px;
                right: 20px;
                max-width: 300px;
            }
        }
    }

    .news {
        margin-top: 55px;

        &:before {
            display: none;
        }

        &__list {
            margin-top: 35px;
        }
    }

    .access {
        &__list {
            padding: 27px 0 28px 20px;
            gap: 15px;

            li {
                &:nth-child(1) {
                    order: 1;
                }

                &:nth-child(2) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 4;
                }

                &:nth-child(4) {
                    order: 5;
                }

                &:nth-child(5) {
                    order: 3;
                }

                &:nth-child(6) {
                    order: 6;
                }
            }
        }

        &__link {
            div {
                margin-right: 7px;
            }

            span {
                margin-right: 5px;
            }
        }
    }

    .home-events {
        margin-top: 75px;

        &:before {
            height: 1115px;
            bottom: 20px;
        }

        &__container {
            padding-bottom: 0;
        }

        &__header {
            flex-direction: column;
        }

        &__navigation {
            left: 50%;
            position: relative;
            transform: translateX(-50%);
            height: 40px;
            margin-top: 25px;
        }

        &__swiper {
            margin-top: 20px;
        }

        &__buttons {
            position: relative;
            bottom: unset;
            right: unset;
            margin-top: 45px;
        }
    }

    .agenda {
        margin-top: 40px;

        &__title {
            font-size: 28px;
        }
    }

    .kiosque {
        &:before {
            left: -20px;
        }

        &__navigation {
            right: 0;
        }
    }

    .home-social {
        &:after {
            right: -150px;
            bottom: 50px;
        }

        &__pictos {
            margin: 30px 0;
        }
    }
}
