.menu-fixe {
    display: none;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .menu-fixe {
        display: flex;
        &__container {
            margin: 0 auto;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }


    // NAV MENU
    .nav-menu {
        &__container {
            z-index: 1;
            min-width: 100%;
            min-height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            height: 0;
            visibility: hidden;
            opacity: 0;
            text-align: start;
            padding-top: 20px;
            z-index: 20;
            transition: all 0.3s ease-in-out;

            &.overlay {
                visibility: visible;
                opacity: 1;
                padding-top: 70px;
                background: $color-dark;
                z-index: 20;
            }
            
        }
    
        &__icon {
            cursor: pointer;
            width: 100%;
            height: 100%;
            margin: 0;
            margin-left: 0;
            background-color: transparent;
            transition: all $duration ease-out;
    
            &--container {
                position: relative;
                display: flex;
                justify-content: center;
                flex-direction: row-reverse;
                align-items: center;
                background-color: transparent;
                color: $color-dark;
                width: fit-content;
                height: 30px;
                gap: 8px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-transform: lowercase;
                transition: all $duration ease-out;
                cursor: pointer;
                overflow: visible;
                margin-bottom: 55px;
            }

            &__close {
                visibility: hidden;
                opacity: 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: $color-white;
                text-transform: lowercase;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
                position: absolute;
                top: 25px;
                left: calc(50% + 225px);
                z-index: 15;

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &__back {
                visibility: hidden;
                opacity: 0;
                cursor: pointer;
                position: absolute;
                top: 25px;
                left: calc(50% - 315px);
                height: 30px;
                display: flex;
                align-items: center;
                z-index: 15;

                svg path {
                    fill: $color-white;
                }

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &__list {
            max-width: 620px;
            margin: 50px auto 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 56px;
            height: 75vh;
        }

        &__item {
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: 30px;
            line-height: 30px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: $color-white;
            cursor: pointer;
            display: flex;

            & .nav-menu__sub {
                left: 100vw;
            }

            &.active {
                .nav-menu__sub {
                    left: 0;
                }
            }
        }

        &__sub {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 620px;
            background: $color-dark;
            height: 100vh;
            padding-top: 40px;
            top: 0;
            width: 100vw;
            z-index: 5;
            left: 0;
            transition: all $duration ease-in-out;

            &--title {
                font-weight: 300;
                font-size: 18px;
                line-height: 19px;
                letter-spacing: 0.05em;
                color: $color-white;
                text-transform: capitalize;
                margin-top: 65px;
                width: 620px;
                left: calc(50% + -310px);
                text-align: start;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 7px;

                span {
                    text-transform: lowercase;
                    font-weight: $font-weight-semibold;
                    transition: all $duration ease-in-out;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -32px;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    background: $color-second;
                    border-radius: 50px;
                }
            }

            .nav-drop {
                margin-top: 55px;
                display: flex;
                flex-direction: column;
                gap: unset;
                height: 60vh;
                width: 100%;
                align-items: center;
                position: relative;
                left: 0;
                overflow: auto;

                li {
                    font-family: $font-family;
                    font-weight: $font-weight-light;
                    font-size: 18px;
                    line-height: 20px;
                    color: $color-white;
                    text-transform: none;
                    text-align: start;
                    position: relative;
                    border-bottom: 1px solid $color-white;
                    width: 620px;
                    padding: 20px 0 10px;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        bottom: -1px;
                        width: 0;
                        height: 1px;
                        background: $color-second;
                        transition: all 0.5s;
                    }

                    &::after {
                        position: absolute;
                        content: "";
                        mask-image: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/Pictos/ic-nav-arrow-droite.svg);
                        mask-repeat: no-repeat;
                        background-color: $color-white;
                        width: 30px;
                        height: 30px;
                        right: 0;
                        transition: all 0.5s;
                    }

                    a {
                        max-width: 580px;
                        display: inline-flex;
                    }

                    &:first-child {
                        padding-top: 0;
                    }

                    &:hover {
                        &::before {
                            width: 100%;
                        }
                        &::after {
                            background-color: $color-second;
                        }

                        a {
                            color: $color-white;
                        }
                    }
                }
            }
        }

        &__link {
            text-align: start;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 7px;
            height: fit-content;
            padding: 0;
            color: $color-white;
            display: flex;
            text-transform: none;
            font-weight: 300;
            font-size: 18px;
            line-height: 19px;
            transition: all $duration ease-in-out;

            span {
                text-transform: lowercase;
                font-weight: $font-weight-semibold;
                transition: all $duration ease-in-out;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: -32px;
                left: 0;
                width: 6px;
                height: 6px;
                background: $color-second;
                border-radius: 50px;
            }
            
            &:hover, &:active, &:focus {
                opacity: 0.7;
                color: $color-white;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .menu-fixe {
        &__container {
            .container {
                min-width: 300px;
                max-width: 300px;
            }
        }

        &__left {
            gap: 40px;
        }
    }

    .nav-menu {
        &__icon {
            &--container {
                margin: 0;
            }
            
            &__back {
                left: calc(50% - 155px);
            }

            &__close {
                left: calc(50% + 65px);
            }
        }

        &__sub {
            .nav-drop {
                li {
                    width: 300px;

                    a {
                        max-width: 265px;
                    }
                }
            }

            &--title {
                width: 300px;
                left: calc(50% - 150px);
            }
        }
    }
}
