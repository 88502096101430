#nav-main {
    z-index: 10;
    margin-right: 50px;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:first-child {
            .nav-main-link {
                padding-left: 0;
            }
        }

        &:last-child {
           .nav-main-link {
                padding-right: 0;

                &:after {
                    width: 0;
                    height: 0;
                    background: transparent;
                }
            }
        }

        &:hover, &:focus, &:active {
            .nav-main-link span {
                color: $color-second;
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            max-width: 160px;
            height: 80px;
            padding: 0 37.5px;
            color: $color-dark;
            text-transform: capitalize;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            font-family: $font-family;
            font-weight: $font-weight-light;
            font-size: 18px;
            line-height: 19px;
            transition: all $duration ease-in-out;

            span {
                text-transform: lowercase;
                font-weight: $font-weight-semibold;
                transition: all $duration ease-in-out;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 25px;
                right: -2px;
                width: 6px;
                height: 6px;
                background: $color-second;
                border-radius: 50px;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 80px 15px;
            background: $color-dark;
            box-shadow: $shadow;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;
                transition: all $duration ease-in-out;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: 100%;
                    height: fit-content;
                    padding: 15px 30px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    color: $color-white;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 25px;
                    transition: all $duration ease-in-out;

                    span {
                        min-width: 6px;
                        width: 6px;
                        height: 6px;
                        background-color: $color-white;
                        border-radius: 50px;
                        transition: all $duration ease-in-out;
                    }
                }

                &:hover {
                    margin-left: 20px;

                    a span {
                        background-color: $color-second;
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-white;

    .navicon {
        position: relative;
        display: block;
        height: 2px;
        top: -1px;
        width: 20px;
        margin-right: 15px;
        background: $color-white;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-white;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        margin-right: 35px;

        .nav-main-item {
            .nav-main-link {
                font-size: 16px;
                padding: 0 25px;
            }

            .sub-menu {
                padding: 50px 15px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        display: none;
    }
}
