.breadcrumb {
    margin-top: -20px;
    position: relative;
    z-index: 2;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 40px;
        width: fit-content;
        background-color: $color-dark;
        padding: 0 15px;
        border-radius: 5px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-white;
        position: relative;
        padding: 0 15px;

        &:not(:last-child) {
            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/ic-nav-chevron-bottom.svg);
                background-color: $color-white;
                mask-repeat: no-repeat;
                top: unset;
                right: -11px;
                width: 22px;
                height: 22px;
                -webkit-mask-size: 22px;
                mask-size: 22px;
                transform: rotate(-90deg);
            }
        }

        a {
            transition: all $duration ease-in-out;

            &:hover {
                color: $color-white;
                text-decoration: underline;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
