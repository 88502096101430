.search-container {
    display: flex;
    position: relative;
    align-items: center;

    .searchInput {
        padding: 24px 40px 24px 24px;
        border: 1px solid $color-dark;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: 50px;
        z-index: 5;
        background-color: $color-white;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .search-svg {
        width: 70px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 7;

        svg {
            width: 15px;
            height: 15px;
            fill: $color-second;
            transition: all $duration;
        }

        &:hover {
            svg {
                fill: $color-main;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: -2px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-top: 1px solid $color-dark;
    padding-top: 70px;
    top: -70px;
    border-radius: 35px 35px 30px 30px;
    filter: drop-shadow(0 0 5px rgba(0,0,0,.1));


    li {
        position: relative;
        background: $color-white;
        border-bottom: 1px solid $color-dark;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-second;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 17px 24px;
                color: $color-dark;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-second;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom:0;
            border-radius: 0 0 35px 35px;
        
            a {
                border-radius: 0 0 30px 30px;
            }
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 20px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 25;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $color-dark;
    transition: all 0.65s;

    .container {
        position: relative;
        height: 100%;

        &:before {
            position: absolute;
            content: "";
            width: 330px;
            height: 387px;
            left: -105px;
            top: 27px;
            background: url(/wp-content/themes/chateaudun/assets/src/images/sprite-images/home/Aigle-search.svg);
            background-repeat: no-repeat;
            z-index: -1;
            background-size: 330px;
        }
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-weight: 600;
            font-size: 40px;
            line-height: 50px;
            color: $color-white;
            text-transform: uppercase!important;
            letter-spacing: 0.05em;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;
            z-index: 2;

            .searchInput {
                background: $color-white;
                border-color: $color-white;
                border-radius: 50px;
                color: $color-dark;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;

                &::placeholder {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;
                    color: $color-dark;
                }

                &:hover, &:focus {
                    border-color: $color-second;
                }
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
            padding-top: 60px;
            top: -60px;
            border-radius: 30px;
            filter: drop-shadow(0 0 5px rgba(0,0,0,.1));
            background-color: $color-white;
            z-index: 0;
        
            li {
                font-family: $font-family--heading;
                font-weight: $font-weight-normal;
                font-size: 16px;
                line-height: 20px;
            }
        
            li:last-child {
                border-radius: 0 0 35px 35px;
        
                a {
                    border-radius: 0 0 30px 30px;
                }
            }
        }

    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        background: $color-second;
        width: 40px;
        height: 57px;
        border-radius: 0px 0px 5px 5px;
        top: 0px;
        margin: 0;
        padding: 0;
        right: 15px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 10px;
        transition: all $duration;
        
        svg {
            width: 20px;
            height: 20px;
            fill: $color-white;
            transition: all $duration;
        }

        &:hover {
            background: $color-main;
        }
    }
}


//======================================================================================================
// Search Page
//======================================================================================================
.filter {
    .search-container {
        .search-svg {
            background: transparent;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            transition: all $duration;
            z-index: 7;

            &:hover {
                svg {
                    fill: $color-main;
                }
            }

            svg {
                fill: $color-second;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        font-size: 2.5em;
    }

}
